<template>
  <PaymentWidget :config="configObj" />
</template>

<script setup lang="ts">
import PaymentWidget from './PaymentWidget.vue'

const props = defineProps<{
  config: string
}>()

const { config } = props

const configObj = JSON.parse(config)
</script>
