<template>
  <div :style="styles.guh_pay_wrapper">
    <div>
      <div :style="styles.containerWrapper">
        <div :style="styles.widgetCard">
          <div :style="styles.borderBottomGray">
            <div :style="{ ...styles.guh_pay_padding_space_y }">
              <div :style="{ ...styles.widgetHeader }">
                <div>
                  <img :style="styles.headerLogo" :src="logo" alt="" />
                </div>
                <div>
                  <h4 :style="styles.widgetHeaderText">
                    Securely Pay for Your Purchases!
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div :style="styles.widgetBody">
            <div>
              <h4 :style="styles.merchantName">{{ QRDataObj.wallet_name }}</h4>
              <p :style="styles.paymentTotal">
                Total: {{ parseInt(QRDataObj.amount).toLocaleString() }} RWF
              </p>
            </div>
            <img
              :src="loadingSpinner"
              style="width: 75px"
              v-if="isLoadingMurugoUser"
            />
            <QRCodeWrapper
              :qrImg="QRDataObj.image"
              :isQRCodeScanned="isQRCodeScanned"
              v-if="
                !allowPayWithGuhembaApp &&
                !isLoadingMurugoUser &&
                hasWalletPaymentOptionEnabled
              "
            />
            <div :style="[styles.borderBottomGray, { marginTop: '6px' }]"></div>
          </div>

          <UserInfo
            v-if="showUserInfo && murugoUserData"
            :userInfo="murugoUserData"
            :approvalEndPointUrl="config.approvalPaymentEndpoint"
            :approvalEndPointHeaders="config.endPointHeader"
            :qrCodeSlug="QRDataObj.slug"
          />

          <div>
            <PaymentOptions
              :supportedPaymentMethods="supportedPaymentMethods"
              :paymentRef="QRDataObj.payment_ref"
              :showPayWithGuh="showGuhPayOption"
              :handlePayWithGuh="handleOpenApproveOnGuhembaApp"
              :qrSlug="QRDataObj.slug"
              :redirectUrl="config.guhRedirect"
              v-if="hidePaymentOptions"
            />
            <div
              v-if="allowPayWithGuhembaApp"
              :style="{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '10px',
                gap: '5px',
                paddingLeft: '20px',
                paddingRight: '20px',
              }"
            >
              <button
                @click="deactivateUIPayWithGuhembaApp"
                :style="[styles.primaryBtn, { background: 'rgb(27 134 174)' }]"
              >
                Go Back
              </button>
            </div>
          </div>
          <div :style="styles.guh_pay_footer">
            <div>
              <p>
                Powered by
                <a
                  :style="styles.guh_pay_footer_link"
                  target="__blank"
                  href="https://guhemba.rw"
                  >Guhemba</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

import styles from './style.ts'

import '../../assets/css/style.css'
import loadingSpinner from '../../assets/images/loading.gif'
import logo from '../../assets/images/logo.png'

import PaymentOptions from './PaymentOptions.vue'
import QRCodeWrapper from './QRCodeWrapper.vue'
import UserInfo from './UserInfo.vue'

import ConfigParams from '../../types/ConfigParams'

import { useMurugoUser } from '../../composables/useMurugoUser'
import { useTransaction } from '../../composables/useTransaction'

const props = defineProps<{
  config: ConfigParams
}>()

const { config } = props

const QRDataObj = JSON.parse(config.QRData)

const { checkTransactionStatus, isQRCodeScanned } = useTransaction(
  QRDataObj,
  config
)
const {
  murugoUserData,
  isLoadingMurugoUser,
  loadMurugoUserInfo,
  deactivateUIPayWithGuhembaApp,
  allowPayWithGuhembaApp,
} = useMurugoUser(config)

const supportedPaymentMethods = computed(() =>
  QRDataObj?.supported_payment_options?.length
    ? QRDataObj.supported_payment_options
    : ['all']
)

const showGuhPayOption = computed((): boolean =>
  Boolean(
    config.approvalPaymentEndpoint &&
      !isQRCodeScanned.value &&
      !allowPayWithGuhembaApp.value
  )
)

const hasWalletPaymentOptionEnabled = computed((): boolean =>
  QRDataObj?.supported_payment_options?.length
    ? QRDataObj?.supported_payment_options.includes('wallet') ||
      QRDataObj?.supported_payment_options.includes('all')
      ? true
      : false
    : false
)

const showUserInfo = computed(
  () => allowPayWithGuhembaApp.value && !isQRCodeScanned.value
)

const hidePaymentOptions = computed(
  () => !allowPayWithGuhembaApp.value && !isQRCodeScanned.value
)

const handleOpenApproveOnGuhembaApp = () => {
  loadMurugoUserInfo()
}

onMounted(() => {
  checkTransactionStatus()
})
</script>
