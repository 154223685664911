<template>
  <a
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    :href="url || ''"
    ref="optionCard"
    :id="`option${name.trim()}`"
    :style="[styles.optionCard, isHovered && styles.optionCardHover]"
  >
    <div :style="[styles.guh_pay_py_2, styles.guh_pay_px_6]">
      <div
        :style="[
          styles.guh_pay_flex,
          styles.guh_pay_items_center,
          styles.guh_pay_justify_between,
        ]"
      >
        <div
          :style="[
            styles.guh_pay_flex,
            styles.guh_pay_items_center,
            styles.guh_pay_gap_3,
          ]"
        >
          <img :style="styles.paymentOptionLogo" :src="image" alt="" />
          <div>
            <p :style="styles.paymentOptionTitle">{{ name }}</p>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import styles from './paymentOptionCardStyles.ts'

interface Props {
  name: string
  image: string
  url?: string
}

const { name, image, url } = defineProps<Props>()

const isHovered = ref(false)

const optionCard = ref(null)

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (optionCard!.value?.id?.replace(/ /g, '') === 'optionPayWithGuhembaApp') {
    const element = optionCard.value
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    element?.setAttribute('href', '#')
  }
})
</script>
