import { ref } from 'vue'
import ConfigParams from '../types/ConfigParams'
import { QrObjectType } from '../types/QrObjectType'

export function useTransaction(QRDataObj: QrObjectType, config: ConfigParams) {
  const intervalId = ref<number | null>(null)
  const isQRCodeScanned = ref<boolean>(false)

  const checkTransactionStatus = async () => {
    if (!QRDataObj) return
    if (!(new Date(QRDataObj.expires_at) > new Date())) return
    try {
      const res = await fetchTransactionStatus()
      if (!res.completed) {
        if (!intervalId.value && new Date(QRDataObj.expires_at) > new Date()) {
          intervalId.value = window.setInterval(() => {
            checkTransactionStatus()
          }, 3000)
        }
        return
      }
      if (res.completed) {
        clearInterval(intervalId.value!)
        intervalId.value = null
        isQRCodeScanned.value = true
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!config.onSuccess) {
          const onSuccessConfigFunc = new Function(
            'response',
            `return (${config.onSuccess})(response)`
          )

          onSuccessConfigFunc(res)
        }
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!config.onError) {
        const onErrorConfigFunc = new Function(
          'errorMsg',
          `return (${config.onError})(errorMsg)`
        )
        onErrorConfigFunc(
          (error as Error).message || 'Something went wrong on our servers'
        )
      }
    }
  }

  const fetchTransactionStatus = async () => {
    const res = await fetch(QRDataObj.transaction_status_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'WALLET-KEY': QRDataObj.public_auth_key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qrcode_id: QRDataObj.id,
        df_web_element: true,
      }),
    })

    if (!res.ok) {
      throw new Error('Failed to fetch transaction status')
    }
    return await res.json()
  }

  return {
    checkTransactionStatus,
    isQRCodeScanned,
    transactionStatusIntervalId: intervalId,
  }
}
