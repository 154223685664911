<template>
  <div>
    <div>
      <div :style="[styles.guh_pay_px_6]">
        <p :style="styles.smTitle">Other Payment Options</p>
      </div>
    </div>
    <div :style="styles.optionCardsContainer">
      <PaymentOptionCard
        v-if="
          supportedPaymentMethods.includes('momo') ||
          supportedPaymentMethods.includes('all')
        "
        name="MTN Mobile Money"
        :image="MtnLogo"
        :url="setPaymentRedirectUrl('mtn')"
      />
      <PaymentOptionCard
        v-if="
          supportedPaymentMethods.includes('card') ||
          supportedPaymentMethods.includes('all')
        "
        name="Credit/Bank Card"
        :image="bankIcon"
        :url="setPaymentRedirectUrl('card')"
      />
      <PaymentOptionCard
        v-if="showPayWithGuh"
        name="Approve On Guhemba App"
        :image="WalletLogo"
        @click.prevent="handlePayWithGuh"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import styles from './style.ts'

import bankIcon from '../../assets/images/bank.png'
import MtnLogo from '../../assets/images/momo.png'
import WalletLogo from '../../assets/images/wallet.png'
import PaymentOptionCard from './PaymentOptionCard.vue'

interface Props {
  supportedPaymentMethods: string[]
  paymentRef: string
  qrSlug: string
  redirectUrl: string
  showPayWithGuh?: boolean
  handlePayWithGuh?: () => void
}

const {
  redirectUrl,
  qrSlug,
  paymentRef,
  supportedPaymentMethods,
  showPayWithGuh,
  handlePayWithGuh,
} = defineProps<Props>()

const setPaymentRedirectUrl = (paymentMethod: string) => {
  return `${redirectUrl}/?payment_ref=${paymentRef}&qrcode_slug=${qrSlug}&payment_option=${paymentMethod}`
}
</script>
