import { CSSProperties } from 'vue'
const styles: { [key: string]: CSSProperties } = {
  guh_pay_wrapper: {
    fontFamily: "'Gilroy',sans-serif",
    boxSizing: 'border-box',
  },
  container: {
    width: '100%',
    padding: '0 20px',
    margin: 'auto',
    boxSizing: 'border-box',
  },
  smFlexBoxStart: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4px',
  },
  guh_pay_modal_overlay: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(200, 200, 200, 0.9)',
  },

  containerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '340px',
    maxWidth: '100%',
  },
  widgetCard: {
    backgroundColor: 'white',
    minHeight: '550px',
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  borderBottomGray: {
    borderBottom: '0.5px solid #e2e8f0',
  },
  guh_pay_padding_space_y: {
    paddingTop: '1rem', // py-4 corresponds to 1rem padding on top and bottom
    paddingBottom: '1rem',
    paddingLeft: '1.5rem', // px-6 corresponds to 1.5rem padding on left and right
    paddingRight: '1.5rem',
    gap: '1rem', // space-y-4 corresponds to 1rem gap between items in the flex direction
  },
  guh_pay_flex_justify_end: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  guh_pay_size_2: {
    width: '0.5rem', // 2 * 0.25rem = 0.5rem
    height: '0.5rem',
  },
  widgetHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerLogo: {
    width: '210px',
    margin: 0,
  },
  widgetHeaderText: {
    fontSize: '16px',
    margin: 0,
    fontWeight: '800',
  },
  merchantName: {
    fontWeight: 700,
    fontSize: '18px',
    textTransform: 'capitalize',
    margin: 0,
  },
  paymentTotal: {
    fontWeight: 600,
    fontSize: '15px',
    textTransform: 'capitalize',
    paddingTop: '4px',
    paddingBottom: '7px',
    margin: 0,
  },
  guh_pay_amount: {
    color: '#00A1DE',
    margin: 0,
    fontSize: '1rem',
  },
  widgetBody: {
    paddingTop: '12px',
    paddingBottom: 0,
    paddingLeft: '1.5rem', // px-6 corresponds to 1.5rem padding on left
    paddingRight: '1.5rem', // px-6 corresponds to 1.5rem padding on right
    textAlign: 'center', // text-center
    gap: '1.5rem', // space-y-6 corresponds to 1.5rem gap between items
  },
  primaryBtn: {
    background: '#00A1DE',
    border: 'none',
    boxShadow:
      '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: '100px',
    height: '40px',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '135%',
    color: '#FFFFFF',
    fontFamily: "'Onest',sans-serif",
    cursor: 'pointer',
    marginTop: '1rem',
  },

  // for qrcodewrapper
  guh_pay_space_y_1: {
    gap: '0.25rem', // space-y-1 corresponds to 0.25rem gap
  },
  smTitle: {
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '500',
    color: '#000',
    margin: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  textPrimary: {
    color: '#00A1DE',
  },
  guh_pay_w_full_flex_items_center_justify_center_bg_center_bg_no_repeat_h_240:
    {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '200px',
    },
  guh_pay_w_40: {
    width: '10rem', // w-40 corresponds to 10rem
  },

  // option cards
  optionCardsContainer: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingBottom: '12px',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  guh_pay_py_2: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  guh_pay_px_6: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  guh_pay_text_sm: {
    fontSize: '0.875rem',
  },
  guh_pay_font_normal: {
    fontWeight: '400',
  },
  guh_pay_text_gray_800: {
    color: '#2d3748', // Tailwind's gray-800 color
  },
  guh_pay_capitalize: {
    textTransform: 'capitalize',
  },

  // footer
  guh_pay_footer: {
    display: 'flex',
    margin: 0,
    fontWeight: 500,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    color: '#666666',
    textAlign: 'center',
  },
  guh_pay_footer_link: {
    color: '#00A1DE',
    textDecoration: 'none',
    fontWeight: '500',
  },

  // userInfo
  avatar: {
    width: '4rem',
    height: '4rem',
    borderRadius: '100%',
    objectFit: 'cover',
  },

  textCenter: {
    textAlign: 'center',
  },
}

export default styles
