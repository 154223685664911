import { CSSProperties } from 'vue'
const styles: { [key: string]: CSSProperties } = {
  optionCard: {
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    marginBottom: '1px',
    marginTop: '1px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    borderRadius: '5px',
  },
  optionCardHover: {
    background: '#ccc',
  },

  guh_pay_py_2: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  guh_pay_px_6: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  guh_pay_flex: {
    display: 'flex',
  },
  guh_pay_items_center: {
    alignItems: 'center',
  },
  guh_pay_justify_between: {
    justifyContent: 'space-between',
  },
  guh_pay_gap_3: {
    gap: '0.75rem',
  },
  paymentOptionLogo: {
    width: '52px',
    borderRadius: '5px',
  },
  paymentOptionTitle: {
    fontSize: '12px',
    color: '#000',
    fontWeight: '700',
  },
}

export default styles
