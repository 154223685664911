import { ref } from 'vue'
import ConfigParams from '../types/ConfigParams'
import { MurugoUserInfo } from '../types/MurugoUserInfo'

export function useMurugoUser(config: ConfigParams) {
  const murugoUserData = ref<MurugoUserInfo | null>(null)
  const isLoadingMurugoUser = ref<boolean>(false)
  const allowPayWithGuhembaApp = ref<boolean>(false)

  const loadMurugoUserInfo = async () => {
    try {
      isLoadingMurugoUser.value = true
      const response = await fetch(
        `${import.meta.env.VITE_MURUGO_URL}/api/public-data`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: `@${config.authUsername}`,
          }),
        }
      )
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const res = await response.json()

      murugoUserData.value = {
        name: res.data.public_name,
        avatarImg: res.data.avatar,
        userName: config.authUsername,
      }

      activateUIPayWithGuhembaApp()
    } catch (error) {
      alert("Error occurred, Can't get Murugo User")
    } finally {
      isLoadingMurugoUser.value = false
    }
  }

  const activateUIPayWithGuhembaApp = () => {
    allowPayWithGuhembaApp.value = true
  }

  const deactivateUIPayWithGuhembaApp = () => {
    allowPayWithGuhembaApp.value = false
  }

  return {
    murugoUserData,
    allowPayWithGuhembaApp,
    isLoadingMurugoUser,
    loadMurugoUserInfo,
    deactivateUIPayWithGuhembaApp,
  }
}
