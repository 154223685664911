<template>
  <div :style="styles.container">
    <h3
      :style="{
        ...styles.smTitleText,
        textAlign: 'center',
      }"
    >
      You were logged in as
    </h3>
    <div :style="styles.smFlexBoxStart">
      <img :style="styles.avatar" :src="userInfo.avatarImg" alt="avatar" />
      <div style="text-align: 'right'">
        <h4
          :style="{
            margin: 0,
            textAlign: 'right',
          }"
        >
          {{ userInfo.name }}
        </h4>
        <p :style="{ margin: 0 }">@{{ userInfo.userName }}</p>
      </div>
    </div>
    <div
      :style="[
        styles.smTitle,
        styles.textCenter,
        {
          paddingTop: '15px',
          paddingBottom: '15px',
          textTransform: 'none',
        },
      ]"
    >
      <p>
        Send payment to the Guhemba App for secure payment processing to this
        user.
      </p>
    </div>
    <div
      :style="[styles.smTitle, styles.textCenter, { textTransform: 'none' }]"
    >
      We do not share this information with the merchant.
    </div>
    <button
      v-if="!isEndPointStatusSent"
      @click="sendPaymentToGuhemba"
      :style="styles.primaryBtn"
    >
      {{ isLoading ? 'Sending...' : 'Send To Guhemba App' }}
    </button>

    <button
      v-if="isEndPointStatusSent"
      @click="openGuhembaAppHandler"
      :style="styles.primaryBtn"
    >
      Open Guhemba App
    </button>
  </div>
</template>

<script setup lang="ts">
import { useApprovalOnGuhemba } from '../../composables/useApprovalOnGuhembaPay'

import { MurugoUserInfo } from '../../types/MurugoUserInfo'
import styles from './style.ts'

interface Props {
  userInfo: MurugoUserInfo
  approvalEndPointUrl?: string
  approvalEndPointHeaders?: string
  qrCodeSlug: string
}

const { userInfo, approvalEndPointUrl, approvalEndPointHeaders, qrCodeSlug } =
  defineProps<Props>()

const {
  isEndPointStatusSent,
  isLoading,
  sendPaymentToGuhemba,
  openGuhembaAppHandler,
} = useApprovalOnGuhemba({
  approvalEndPointUrl,
  approvalEndPointHeaders,
  qrCodeSlug,
  userName: userInfo.userName,
})
</script>
