import { defineCustomElement, ref } from 'vue'
import PaymentWidget from '../components/PaymentWidget/PaymentWidget.ce.vue'
import ConfigParams from '../types/ConfigParams'

const PaymentWidgetElement = defineCustomElement(PaymentWidget)

customElements.define('guh-payment-widget', PaymentWidgetElement)

const webComponetConfig = ref({})

const initializePayment = (targetDiv: string, config: ConfigParams) => {
  webComponetConfig.value = config

  if (config.onError) {
    webComponetConfig.value = { ...config, onError: `${config.onError}` }
  }

  if (config.onSuccess) {
    webComponetConfig.value = { ...config, onSuccess: `${config.onSuccess}` }
  }

  mountComponent(targetDiv)
}

const mountComponent = (mountDiv: string) => {
  const myElement = document.createElement('guh-payment-widget')

  myElement.setAttribute('config', JSON.stringify(webComponetConfig.value))

  const mountTarget = document.querySelector(mountDiv)

  if (mountTarget) {
    mountTarget.appendChild(myElement)
  } else {
    console.error(`Element with ID '${mountDiv}' not found.`)
  }
}

export const useGuhembaPay = (targetDiv: string, config: ConfigParams) => {
  return {
    initializePayment: () => initializePayment(targetDiv, config),
  }
}
