import * as Sentry from '@sentry/browser'
import { useGuhembaPay } from './composables/useGuhembaPay'
import ConfigParams from './types/ConfigParams'

// Initialize Sentry
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const GuhembaPayCheckout = (targetDiv: string, config: ConfigParams) => {
  const { initializePayment } = useGuhembaPay(targetDiv, config)
  return initializePayment()
}

declare global {
  interface Window {
    GuhembaPayCheckout: typeof GuhembaPayCheckout
  }
}

window.GuhembaPayCheckout = GuhembaPayCheckout
