import { ref } from 'vue'

type approvalParams = {
  approvalEndPointUrl?: string
  approvalEndPointHeaders?: string
  userName?: string
  qrCodeSlug: string
}

export function useApprovalOnGuhemba({
  approvalEndPointUrl,
  approvalEndPointHeaders,
  userName,
  qrCodeSlug,
}: approvalParams) {
  const isEndPointStatusSent = ref(false)
  const isLoading = ref(false)

  const sendPaymentToGuhemba = async () => {
    if (approvalEndPointUrl) {
      try {
        let headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }

        if (approvalEndPointHeaders) {
          headers = {
            ...headers,
            ...JSON.parse(approvalEndPointHeaders),
          }
        }

        isEndPointStatusSent.value = false
        isLoading.value = true

        const response = await fetch(approvalEndPointUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            customer_atname: userName,
            qrcode_slug: qrCodeSlug,
          }),
        })
        if (!response.ok) {
          const errorBody = await response.json()
          throw new Error(
            `${
              errorBody.message
                ? errorBody.message
                : errorBody.error
                  ? errorBody.error
                  : `Can't send payment to your guhemba app`
            }`
          )
        }
        isEndPointStatusSent.value = true
      } catch (error) {
        window.alert("Can't send payment to your guhemba app")
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }
  }

  const openGuhembaAppHandler = () => {
    const userAgent = navigator.userAgent || navigator.vendor

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      launchGuhembaAppOnIOSDevice()
    } else if (/android/i.test(userAgent)) {
      launchAppOnAndroidDevice()
    } else {
      alert('Not supported on this platform')
    }
  }

  const launchGuhembaAppOnIOSDevice = () => {
    const url = 'guhemba://'

    let appOpened = false

    document.addEventListener('visibilitychange', function () {
      if (!document.hidden && !appOpened) {
        window.location.replace(
          'https://apps.apple.com/us/app/guhemba/id1566090499'
        )
      }
    })

    window.location.replace(url)
    setTimeout(() => {
      appOpened = true
    }, 1500)
  }

  const launchAppOnAndroidDevice = () => {
    const packageName = 'com.rwbuild.android.dev.guhemba.android'
    const url =
      'intent://app/#Intent;scheme=guhemba;package=' + packageName + ';end'

    let appOpened = false

    document.addEventListener('visibilitychange', function () {
      if (!document.hidden && !appOpened) {
        window.location.replace(
          'https://play.google.com/store/apps/details?id=com.rwbuild.android.dev.guhemba.android'
        )
      }
    })

    window.location.replace(url)

    setTimeout(() => {
      appOpened = true
    }, 1500)
  }

  return {
    isEndPointStatusSent,
    isLoading,
    sendPaymentToGuhemba,
    openGuhembaAppHandler,
  }
}
